
import React, {useState} from "react"
import Layout from "../../components/layout"

import WebappForm from "../../components/webapps/form"

const negosyonowAPI = require("../../../lib/requestNegosyownow")
const webappsAPI = require("../../../lib/requestWebapps")

const ENTITYID="nnfranchise"



const onsorganizationpickerfields = [
	{"label":"Name", "dbfield": "onsbranch_name", "type": "text", "filtertype": "textbox"},
	{"label":"URL", "dbfield": "onsbranch_url", "type": "text", "filtertype": "textbox"}
];

const onsorganizationpickerfilter = [
	{"field":"onsorganization_id", "operation": ">", "value": 0},
];


const searchFields = [
	{"label":"Franchisor", "dbfield": "onsorganization_name", "type": "text", "filtertype": "text"},
	{"label":"Name", "dbfield": "nnfranchise_name", "type": "text", "filtertype": "text"},
	{"label":"Accepting Applicants", "dbfield": "nnfranchise_active", "type": "checkbox", "filtertype": "checkbox"},
	{"label":"Hidden", "dbfield": "nnfranchise_hidden", "type": "checkbox", "filtertype": "checkbox"},
	{"label":"Logo", "dbfield": "nnfranchise_logo", "type": "image", "filtertype": "image"},
	{"label":"Industry", "dbfield": "nnfranchiseindustry_name", "type": "text", "filtertype": "text"},
	{"label":"Summary", "dbfield": "nnfranchise_summary", "type": "text", "filtertype": "text"}
];


const commonFormFields = [
	{"label":"Has Page", "field": "nnfranchise_haspage", "value": "", "input": "hidden", "mode": "readonly"},
	{"label":"Webpage", "field": "nnfranchise_actualurl", "value": "", "input": "url", "mode": "readonly", "urlprefix":"https://negosyonow.com/franchise/"},
	{"label":"Logo", "field": "nnfranchise_logo", "value": "", "input": "image", "mode": "normal", "imagemode":"fit", "imagewidth":2048, "imageheight":2048, "imageformat":"png", "uploadtype": "https","filehost": "cdn.ngnw.ph", "filelocation":"images/franchise/logo/full", "info":"Displayed in the listing page, use white/transparent background"},

	{"label":"Teaser", "field": "nnfranchise_summary", "value": "", "input": "textarea", "mode": "normal","info":"Displayed in listing pages"},

	{"label":"Description", "field": "nnfranchise_description", "value": "", "input": "textarea", "mode": "normal","info":"Displayed in franchise pages"},

	{"label":"Terms", "field": "nnfranchise_termsmonth", "value": "", "input": "integer", "mode": "required","info":"# of months"},

	{"label":"Royalty", "field": "nnfranchise_royalty", "value": "", "input": "textbox", "mode": "required"},

	{"label":"Franchise Fee", "field": "nnfranchise_franchisefee", "value": "", "input": "textbox", "mode": "required"},

	{"label":"Mobile Banner", "field": "nnfranchise_bannermobile", "value": "", "input": "image", "mode": "normal", "imagemode":"fit", "imagewidth":1024, "imageheight":1024, "imageformat":"jpg", "uploadtype": "https","filehost": "cdn.ngnw.ph", "filelocation":"images/franchise/banner/mobile","info":"Top image of franchise page (mobile devices)"},

	{"label":"Desktop Banner", "field": "nnfranchise_bannerdesktop", "value": "", "input": "image", "mode": "normal", "imagemode":"fit", "imagewidth":1200, "imageheight":480, "imageformat":"jpg", "uploadtype": "https","filehost": "cdn.ngnw.ph", "filelocation":"images/franchise/banner/desktop", "info":"Top image of franchise page (desktop/tablets)"},


	{"label":"Content Page Image", "field": "nnfranchise_contentimage", "value": "", "input": "image", "mode": "normal", "imagemode":"fit", "imagewidth":1200, "imageheight":480, "imageformat":"jpg", "uploadtype": "https","filehost": "cdn.ngnw.ph", "filelocation":"images/franchise/contentbanner","info":"Top image of franchise application and resources pages"},

	{"label":"Welcome message", "field": "nnfranchise_contentdescription", "value": "", "input": "textarea", "mode": "normal","info":"Displayed in franchise resource page. Will use the summary text if empty."},
];

const newFormFields = [
	[
		{"label":"Franchisor", "field": "onsbranch_name", "value": "", "input": "picker", "mode": "required", "pickerfields": onsorganizationpickerfields, "pickerfilters": onsorganizationpickerfilter},
		{"label":"Name", "field": "nnfranchise_name", "value": "", "input": "textbox", "mode": "unique"},
		{"label":"Industry", "field": "nnfranchiseindustry_name", "value": "", "input": "picker", "mode": "required"}
	]
];

const formFields = [
	[
		{"label":"Franchisor", "field": "onsorganization_name", "value": "", "input": "picker", "mode": "readonly"},
		{"label":"Name", "field": "nnfranchise_name", "value": "", "input": "textbox", "mode": "readonly"},
		{"label":"Industry", "field": "nnfranchiseindustry_name", "value": "", "input": "picker", "mode": "readonly"},
		{"label":"Accepting Applicants", "field": "nnfranchise_active", "value": "", "input": "checkbox", "mode": "normal","info":"Accept new applications"},
		{"label":"Hidden", "field": "nnfranchise_hidden", "value": "", "input": "checkbox", "mode": "normal","info":"Remove from franchise page"},
	]
];


const subformFields = [
	{
		"subformid": "franchisepackage",
		"label": "Packages",
		"table": "nnfranchisepackagecode",
		"sort": "nnfranchisepackagecode_name",
		"mobilerowtitlefieldidx":[1],
		"minrow": 0,
		"maxrow": 10,
		"allowdel": false,
		"fieldlist": [
			{
				"label": "Name",
				"field": "nnfranchisepackagecode_name",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "required",
			},
			{
				"label": "Code",
				"field": "nnfranchisepackagecode_skucode",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "normal",
			},
			{
				"label": "Active",
				"field": "nnfranchisepackagecode_active",
				"subtotalid": "",
				"value": "",
				"input": "checkbox",
				"mode": "normal"
			},
			{
				"label": "Description",
				"field": "nnfranchisepackagecode_description",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "normal"
			},
		],
		"footerlist": [
		]
	},
	{
		"subformid": "sociallink",
		"label": "Website and Social Media",
		"table": "nnfranchisesocial",
		"sort": "nnfranchisesocial_id",
		"mobilerowtitlefieldidx":[2],
		"minrow": 0,
		"maxrow": 5,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Type",
				"field": "linkicon_name",
				"subtotalid": "",
				"value": "",
				"input": "picker",
				"mode": "required",
			},
			{
				"label": "URL",
				"field": "nnfranchisesocial_url",
				"subtotalid": "",
				"value": "",
				"input": "url",
				"mode": "required"
			},
		],
		"footerlist": [
		]
	},
	{
		"subformid": "images",
		"label": "Images",
		"table": "nnfranchiseimage",
		"sort": "nnfranchiseimage_order",
		"mobilerowtitlefieldidx":[1],
		"minrow": 0,
		"maxrow": 5,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Image",
				"field": "nnfranchiseimage_url",
				"subtotalid": "",
				"value": "",
				"input": "image",
				"imageformat":"jpg", // OPTIONAL: [png, jpg] default png
				"mode": "normal",
				"uploadtype": "https", // OPTIONAL: [https, webapp] Storage format, https=raw
				"filehost": "cdn.ngnw.ph", // OPTIONAL: webhost; no slashes and http/https prefix
				"filelocation": "images/franchise/uploads/subimage", // OPTIONAL: folder for file; no leading and tailing slashes
				"imagemode": "fit",	// OPTIONAL: [as-is, limit, fit, fill, stretch] Resize/cropping rule
				"imagewidth": 1024,	// OPTIONAL: Will be required for some imagemode
				"imageheight": 1024,	// OPTIONAL: Will be required for some imagemode

			},
			{
				"label": "Notes",
				"field": "nnfranchiseimage_name",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "normal"
			},
		],
		"footerlist": [
		]
	},
	{
		"subformid": "deployformat",
		"label": "Formats",
		"table": "nnfranchisedeployment",
		"sort": "nnfranchisedeploytype_id",
		"mobilerowtitlefieldidx":[1],
		"minrow": 0,
		"maxrow": 5,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Type",
				"field": "nnfranchisedeploytype_name",
				"subtotalid": "",
				"value": "",
				"input": "picker",
				"mode": "required",
			},
			{
				"label": "Min. SQM",
				"field": "nnfranchisedeployment_minsqm",
				"subtotalid": "",
				"value": "",
				"input": "numeric",
				"mode": "required"
			},
			{
				"label": "Investment",
				"field": "nnfranchisedeployment_investment",
				"subtotalid": "",
				"value": "",
				"input": "currency",
				"mode": "required"
			},
			{
				"label": "Notes",
				"field": "nnfranchisedeployment_notes",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "normal"
			},
		],
		"footerlist": [
		]
	},
	{
		"subformid": "faqlist",
		"label": "FAQ",
		"table": "nnfranchisefaq",
		"sort": "nnfranchisefaq_order",
		"mobilerowtitlefieldidx":[1],
		"minrow": 0,
		"maxrow": 5,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Order",
				"field": "nnfranchisefaq_order",
				"subtotalid": "",
				"value": "",
				"input": "integer",
				"mode": "required",
			},
			{
				"label": "Question",
				"field": "nnfranchisefaq_question",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "required"
			},
			{
				"label": "Answer",
				"field": "nnfranchisefaq_answer",
				"subtotalid": "",
				"value": "",
				"input": "textarea",
				"mode": "required"
			},
		],
		"footerlist": [
		]
	}
];




const FranchisePage = ({location}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");
	const [newpackageflag, setNewpackageflag] = useState(false);

	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		var pkid = 0;
		if (params.hasOwnProperty("documentid")) {
			if (params.documentid) {
				pkid = params.documentid;
			}
		}
		if (pkid === 0) {
			params.nnfranchiseq_id=2;	// Basic Information Sheet
		}

		if (params.hasOwnProperty("onsbranch_id")) {
			webappsAPI.loadRecord("onsbranch", params.onsbranch_id, token).then(dataresponse => {
				if (dataresponse.hasOwnProperty("fields")) {
					var rowidx = 0;
					var colidx = 0;
					var tmpfield = "";
					while (rowidx < dataresponse.fields.length) {
						colidx = 0;
						while (colidx < dataresponse.fields[rowidx].length) {
							tmpfield = dataresponse.fields[rowidx][colidx].field;
							if (tmpfield === "onsorganization_id") {
								params.onsorganization_id = dataresponse.fields[rowidx][colidx].value;
							}
							colidx++;
						} // while col
						rowidx++;
					} // while row
					delete params.onsbranch_id;

					webappsAPI.savePayload(entity, params, token, payloadlist, subformpayloadlist, function(response){
						if (response.status === "OK") {
							negosyonowAPI.activateEntity(response.documentid,token, "nnfranchise").then(nnactivateresponse => {
								checkandactivatepackages(response.documentid, function(newpackagesres) {
									callback(response);
								});
							});
						} else {
							callback(response);
						}
					});
					return;
				} // if fields
				callback({"status":"Error","message":"Unable to load organization information"});

			});
			return;
		} // has branch
		// Fallthrough: Existing document or no branch
		webappsAPI.savePayload(entity, params, token, payloadlist, subformpayloadlist, function(response){
			if (response.status === "OK") {
				negosyonowAPI.buildEntityId(response.documentid,token, "franchise").then(nnresponse => {
					checkandactivatepackages(response.documentid, function(additionalpackagesres) {
						callback(response);
					});
				});
			} else {
				callback(response);
			}
		});

		//callback({"status":"Error"});
	}

	function checkandactivatepackages(pkid, callback)
	{
		if (newpackageflag === false) {
			callback({});
			return;
		}
		webappsAPI.loadData("nnfranchisepackagecode", {
			"dbfieldlist":["nnfranchisepackagecode_id"],
			"filters":[[
				{
					field: "nnfranchisepackagecode_code",
					operation: "=",
					value: ""
				},
				{
					field: "nnfranchise_id",
					operation: "=",
					value: pkid
				}
			]]
		}, token).then(dataresponse => {
			if (dataresponse.status === "OK") {
				var idlist = [];
				var idx = 0;
				while (idx < dataresponse.data.length) {
					idlist.push(dataresponse.data[idx].nnfranchisepackagecode_id);
					idx++;
				}
				if (idlist.length > 0) {
					activatepackagelist(0, idlist, function(res) {
						callback(dataresponse);
					});
					return;
				}
			}
			callback(dataresponse);
		});
	}

	function activatepackagelist(idx, idlist, callback)
	{
		if (idx < idlist.length) {
			console.log("negosyonowAPI.activateEntity", idlist[idx], "nnfranchisepackagecode");
			negosyonowAPI.activateEntity(idlist[idx],token, "nnfranchisepackagecode").then(response => {
				activatepackagelist(idx+1, idlist, callback);
			});
		} else {
			callback({});
		}
	}

	function customEditFieldInfo(inputlist, idx)
	{
		if (inputlist[idx].field !== "nnfranchise_actualurl") {
			return inputlist[idx];
		}
		const haspage = parseInt(inputlist[idx-1].value);
		if (haspage === 1) {
			return inputlist[idx];
		}
		var tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
		tmpfield.value = "Not yet available";
		tmpfield.input = "textbox";
		return tmpfield;
	}

	function customValidateValues(mainform, subformlist, footerformlist)
	{
		var outnewpackageflag = false;
		var subformidx = 0;
		var rowidx = 0;
		var fieldidx = 0;
		var curid = 0;
		while (subformidx < subformlist.length) {
			rowidx = 0;
			while (rowidx < subformlist[subformidx].length) {
				fieldidx = 0;
				while (fieldidx < subformlist[subformidx][rowidx].length) {
					if (subformlist[subformidx][rowidx][fieldidx].field === "nnfranchisepackagecode_id") {
						curid = 0;
						if (subformlist[subformidx][rowidx][fieldidx].value) {
							curid = parseInt(subformlist[subformidx][rowidx][fieldidx].value,10);
						}
						if (curid === 0) {
							outnewpackageflag = true;
							setNewpackageflag(true);
							return {"status":"OK"};
						}
					}
					fieldidx++;
				}
				rowidx++;
			}
			subformidx++;
		}
		setNewpackageflag(outnewpackageflag);
		return {"status":"OK"};
	}

	return <Layout
			fullPath={location.pathname}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			privatePage={true} usegatedcontent={true}
		>
			<WebappForm
				pagetitle={pagetitle}
				allowdelete={false}
				entity={ENTITYID}
				mobilerowtitlefield={["nnfranchise_name"]}
				searchFields={searchFields}
				editFields={[formFields[0].concat(commonFormFields)]}
				newformFields={[newFormFields[0].concat(commonFormFields)]}
				editSubFormFields={subformFields}
				newSubformFields={[]}
				customSubmit={customSubmit}
				customValidateValues={customValidateValues}
				customEditFieldInfo={customEditFieldInfo}
				token={token} />
		</Layout>

}


export default FranchisePage;
